import React from "react";
import "../css/styles.css";

const Header = () => {
  return (
    <header>
      <a className="image-link" href="/">
        <h1 className="logo-header">Martin Disley</h1>
      </a>
      <a className="image-link" href="mailto:martin_disley[at]icloud.com">
        <button className="contact-button">Contact</button>
      </a>
    </header>
  );
};

export default Header;
