import * as React from "react";
import Header from "../components/Header";

const NotFoundPage = () => {
  return (
    <div>
      <title>404, Not Found</title>
      <Header />
      <div className="portfolio-text-container">
        <h2>404, page not found.</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;
